<template>
  <div class="left-menu">
    <div class="logotitle">
      <!--            <img class="logo__img" src="https://assets.zjmall.okzuji.com/app_logo.png" />-->
      <span v-show="showLogo">家悦充</span>
    </div>

    <el-menu
      ref="menu"
      :default-active="activeMenu"
      class="el-menu-vertical-demo"
      background-color="#001529"
      text-color="#fff"
      active-text-color="#409EFF"
      unique-opened
      :collapse="!showLogo"
      router
      @open="openMenu"
      @select="selectMenu"
    >
      <template v-for="item in menuList">
        <!-- 不包含子菜单的“一级菜单” -->
        <el-menu-item :index="item.path" :key="item.path" v-if="!item.children">
          <i :class="item.meta.icon"></i>
          <span slot="title">{{ item.meta && item.meta.name }}</span>
        </el-menu-item>

        <!-- 包含子菜单的“一级菜单” -->
        <el-submenu :index="item.path" :key="item.path" v-else>
          <template slot="title">
            <div class="menu-title">
              <span class="left">
                <i :class="item.meta.icon"></i>
                <span>{{ item.meta && item.meta.name }}</span>
              </span>
              <div class="right">
                <span v-if="item.name == 'Recharge'" class="num-box">
                  <span v-if="wait_total > 0" class="num">
                    {{ wait_total > 99 ? '99+' : wait_total }}
                  </span>
                </span>
              </div>
            </div>
          </template>
          <!-- 循环渲染“二级菜单” -->
          <template v-for="subItem in item.children">
            <el-menu-item :index="subItem.path" :key="subItem.path" v-if="!subItem.meta.hidden">
              <div class="sub-title">
                <div class="left">
                  <i :class="subItem.meta.icon"></i>
                  {{ subItem.meta && subItem.meta.name }}
                </div>
                <div class="right">
                  <span v-if="subItem.name == 'WithdrawList'" class="num-box">
                    <span v-if="wait_total > 0" class="num">
                      {{ wait_total > 99 ? '99+' : wait_total }}
                    </span>
                  </span>
                </div>
              </div>
            </el-menu-item>
          </template>
        </el-submenu>
      </template>
    </el-menu>
  </div>
</template>

<script>
import { getWithdrawListAPI } from './api'
import { mapGetters } from 'vuex'
export default {
  name: 'LeftMenu',

  data() {
    return {
      isCollapse: false,
      showLogo: true,
      defaultSelectMenu: this.$route.path,
      currentOpenKey: '',
      wait_total: 10,
      timer: null
    }
  },
  computed: {
    ...mapGetters(['getResultRoutes']),

    menuList() {
      const routes = this.getResultRoutes
      return routes.filter((item) => {
        return !item.meta.hidden
      })
    },
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    }

    // defaultSelect() {
    //   let path = this.$route.fullPath.slice(1)
    //   let rootPath = path.split('/')
    //   return [`/${rootPath[0]}`]
    // }
  },
  mounted() {
    this.defaultSelectMenu = sessionStorage.getItem('keyPath') || '/home'
    this.$bus.$on('showLogo', () => {
      this.showLogo = !this.showLogo
    })
    this.getWithdrawList()
    if (!this.timer) {
      this.handleTimer()
    }
  },
  watch: {
    defaultSelectMenu: {
      immediate: true,
      handler(newV, oldV) {
        // if (newV == '/') this.defaultSelectMenu = '/home'
        // if (newV != this.$route.path) {
        //   this.defaultSelectMenu = this.$route.path
        // }
      }
    },
    $route: {
      handler(newV, oldV) {
        this.defaultSelectMenu = this.$route.path
      }
    }
  },
  methods: {
    handleTimer() {
      this.timer = setInterval(() => {
        this.getWithdrawList()
      }, 5000)
    },
    async getWithdrawList() {
      const params = { status: 0 }
      const res = await getWithdrawListAPI(params)
      this.wait_total = res.total || 0
    },
    openMenu(index, indexPath) {
      // this.currentOpenKey = index
      // this.defaultSelectMenu = this.menuList.find(item => item.path == index).redirect
      // this.$router.push(this.defaultSelectMenu)
      sessionStorage.setItem('keyPath', this.defaultSelectMenu)
    },
    selectMenu(index, indexPath) {
      sessionStorage.setItem('keyPath', index)
      if (index == '/home') {
        this.$refs.menu.close(`/${this.$route.path.split('/')[1]}`)
        this.defaultSelectMenu = index
      }
    }

    // goPage({ path }) {
    //   console.log(path)
    //   this.$router.push({
    //     path
    //   })
    // }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>

<style lang="scss" scoped>
.left-menu /deep/ {
  height: 100%;
  overflow-y: scroll;
  .logotitle {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 20px 20px 20px;
    font-size: 20px;
    color: #fff;
    font-weight: bold;
    text-align: center;

    .logo__img {
      width: 30px;
      margin-right: 10px;
    }
  }
  .el-menu {
    border: none;
    .el-menu-item {
      color: rgba(255, 255, 255, 0.8) !important;
    }
    .el-submenu__title {
      color: rgba(255, 255, 255, 0.8) !important;
      i {
        color: rgba(255, 255, 255, 0.8) !important;
      }
    }
  }
  .el-menu--collapse {
    width: 100%;
  }

  .el-submenu {
    .el-menu-item {
      padding: 0 0 0 50px !important;
    }
  }
  .el-menu-item.is-active {
    background-color: rgb(64, 158, 255) !important;
    color: #fff !important;
  }
  .menu-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .right {
      margin-right: 20px;
      .num-box {
        .num {
          background-color: #f56c6c;
          border-radius: 10px;
          color: #fff;
          font-size: 12px;
          padding: 1px 5px;
          text-align: center;
          white-space: nowrap;
        }
      }
    }
  }
  .sub-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .right {
      margin-right: 20px;
      .num-box {
        .num {
          background-color: #f56c6c;
          border-radius: 10px;
          color: #fff;
          font-size: 12px;
          padding: 1px 5px;
          text-align: center;
          white-space: nowrap;
        }
      }
    }
  }
}
.left-menu::-webkit-scrollbar {
  width: 0px;
  background-color: #ccc;
}
</style>
