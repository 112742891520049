import { http } from '@/plugins/axios'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { router } from '@/router'
import { loginApi, logoutApi } from '@/api/manage'
import { setCookie } from '@/utils/support'
import { asyncRoutes, constantRoutes, anyRoutes } from '@/router/routes'

const computeAsyncRoutes = (oriAsyncRoutes, authMenus = []) => {
  return oriAsyncRoutes.filter((item) => {
    if (authMenus.indexOf(item.name) != -1) {
      if (item.children && item.children.length > 0) {
        item.children = computeAsyncRoutes(item.children, authMenus)
      }
      return true
    }
  })
}

const user = {
  state: {
    token: getToken(),
    // name: '',
    // avatar: '',
    // roles: [],
    userInfo: {},
    isAdmin: 1,
    authMenus: []
    // authBtns: [],
    // resultAsyncRoutes: [], // 对比后的最终路由
    // resultAllRoutes: []
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    // SET_NAME: (state, name) => {
    //   state.name = name
    // },
    SET_AUTH_MENU: (state, authMenu) => {
      state.authMenus = authMenu
      // state.authBtns = authMenu
    },
    SET_IS_ADMIN: (state, isAdmin) => {
      state.isAdmin = isAdmin
    },
    // SET_AVATAR: (state, avatar) => {
    //   state.avatar = avatar
    // },
    // SET_ROLES: (state, roles) => {
    //   state.roles = roles
    // },
    SET_USER_INFO: (state, userInfo) => {
      state.userInfo = userInfo
    }
    // SET_RESULT_ASYNC_ROUTES: (state, asyncRoutes) => {
    //   state.resultAsyncRoutes = asyncRoutes
    //   let routes = constantRoutes.concat(asyncRoutes, anyRoutes)
    //   routes.forEach(item => {
    //     if (item.children && item.children.length > 0) {
    //       for (let i = 0; i < item.children.length; i++) {
    //         if (item.children[i].meta && !item.children[i].meta.hidden) {
    //           item.redirect = item.children[i].path
    //           break
    //         }
    //       }
    //     }
    //   })

    //   router.addRoutes(routes)
    // }
  },

  getters: {
    authBtns(state) {
      return state.authMenus || []
    },

    isAdmin: (state) => state.isAdmin,

    getResultRoutes: (state) => {
      const calAsyncRoutes = state.isAdmin ? asyncRoutes() : computeAsyncRoutes(asyncRoutes(), state.authMenus)
      let res = constantRoutes.concat(calAsyncRoutes, anyRoutes)
      res.forEach((item) => {
        if (item.children && item.children.length > 0) {
          for (let i = 0; i < item.children.length; i++) {
            if (item.children[i].meta && !item.children[i].meta.hidden) {
              item.redirect = item.children[i].path
              break
            }
          }
        }
      })

      router.addRoutes(res)
      return res
    }
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      // const account = userInfo.account.trim()
      return new Promise((resolve, reject) => {
        // let loginData = {
        //   account: account,
        //   password: userInfo.password
        // }

        loginApi(userInfo)
          .then((res) => {
            setToken(res.authorization)
            commit('SET_TOKEN', res.authorization)
            commit('SET_USER_INFO', res.login_info)
            commit('SET_IS_ADMIN', res.login_info.is_super)
            commit('SET_AUTH_MENU', res.auth_perms || [])
            resolve()
          })
          .catch((error) => {
            console.log('error-----', error)
            reject(error)
          })

        // http({
        //   url: '/admin/auth/login',
        //   method: 'POST',
        //   data: {
        //     mobile: username,
        //     password: userInfo.password,
        //     rcKey: userInfo.rcKey,
        //     code: userInfo.code
        //   }
        // })
        //   .then(response => {
        //     const tokenStr = response.token
        //     let authMenus = response.routeCodeList || []
        //     let isAdmin = response.isSuper || 0
        //     setToken(tokenStr)
        //     commit('SET_TOKEN', tokenStr)
        //     commit('SET_IS_ADMIN', isAdmin)
        //     commit('SET_AUTH_MENU', authMenus)
        //     commit('SET_USER_INFO', response.userName)
        //     commit('SET_RESULT_ASYNC_ROUTES', isAdmin ? asyncRoutes() : computeAsyncRoutes(asyncRoutes(), authMenus))
        //     localStorage.setItem('userName', username)
        //     resolve()
        //   })
        //   .catch(error => {
        //     console.log('error-----', error)
        //     reject(error)
        //   })
      })
    },

    // 获取用户信息
    GetInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getInfo()
          .then((response) => {
            const data = response.data
            if (data.roles && data.roles.length > 0) {
              // 验证返回的roles是否是一个非空数组
              commit('SET_ROLES', data.roles)
            } else {
              reject('getInfo: roles must be a non-null array !')
            }
            commit('SET_NAME', data.username)
            commit('SET_AVATAR', data.icon)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 退出
    Logout({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        logoutApi()
          .then(() => {
            dispatch('tagsView/delAllViews')
            commit('SET_TOKEN', '')
            commit('SET_ROLES', [])
            removeToken()
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise((resolve) => {
        commit('SET_TOKEN', '')
        removeToken()
        resolve()
      })
    }
  }
}

export default user
