// 常量路由

export const constantRoutes = [
  {
    path: '/',
    redirect: '/home',
    meta: {
      hidden: true
    }
  },
  {
    path: '/login',
    meta: {
      hidden: true
    },
    component: () => import('@/views/login/index')
  },
  {
    path: '/home',
    name: 'Home',
    meta: {
      icon: 'el-icon-s-home',
      name: '首页'
    },
    component: () => import('../views/home/index.vue')
  }
]

// 任意路由
export const anyRoutes = [
  //
]

// 动态路由
export const asyncRoutes = () => [
  {
    path: '/user',
    name: 'User',
    component: () => import('@/views/user/index.vue'),
    meta: { name: '用户管理', icon: 'el-icon-s-custom' },
    children: [
      {
        path: '/user/list',
        name: 'UserVipList',
        component: () => import('@/views/user/user-list/list.vue'),
        meta: { name: '用户列表' }
      },
      // {
      //   path: '/user/detail',
      //   name: 'UserDetail',
      //   component: () => import('@/views/user/user-list/detail.vue'),
      //   meta: { name: '用户详情', hidden: true, activeMenu: '/user/list' }
      // },
      {
        path: '/user/car/list/:id?',
        name: 'UserCarList',
        component: () => import('@/views/user/user-list/modules/user-car-list.vue'),
        meta: { name: '用户车辆', hidden: true, activeMenu: '/user/list' }
      },
      {
        path: '/user/balance/list/:id?',
        name: 'UserBalanceList',
        component: () => import('@/views/user/user-list/modules/user-balance-list.vue'),
        meta: { name: '用户流水', hidden: true, activeMenu: '/user/list' }
      }
    ]
  },
  {
    path: '/order',
    name: 'Order',
    component: () => import('@/views/order/index.vue'),
    meta: { name: '订单管理', icon: 'el-icon-s-order' },
    children: [
      {
        path: '/order/pay/list',
        name: 'PayList',
        component: () => import('@/views/order/pay/list.vue'),
        meta: { name: '订单列表', keepAlive: true }
      },
      {
        path: '/order/pay/detail/:id?',
        name: 'PayDetail',
        component: () => import('@/views/order/pay/detail.vue'),
        meta: { name: '订单详情', hidden: true, activeMenu: '/order/pay/list' }
      },
      // {
      //   path: '/order/seat/list',
      //   name: 'SeatList',
      //   component: () => import('@/views/order/seat/list.vue'),
      //   meta: { name: '占位订单' }
      // },
      // {
      //   path: '/order/seat/detail/:id?',
      //   name: 'SeatDetail',
      //   component: () => import('@/views/order/seat/detail.vue'),
      //   meta: { name: '详情', hidden: true, activeMenu: '/order/seat/list' }
      // },

      {
        path: '/order/appoint/list',
        name: 'OrderAppointList',
        component: () => import('@/views/order/appoint/list.vue'),
        meta: { name: '预约订单' }
      },
      {
        path: '/order/appoint/detail/:id?',
        name: 'OrderAppointDetail',
        component: () => import('@/views/order/appoint/detail.vue'),
        meta: { name: '预约订单详情', hidden: true, activeMenu: '/order/appoint/list' }
      }
    ]
  },

  {
    path: '/recharge',
    name: 'Recharge',
    component: () => import('@/views/recharge/index.vue'),
    meta: { name: '充值管理', icon: 'el-icon-money' },
    children: [
      {
        path: '/recharge/rechargeOrder/list',
        name: 'RechargeOrderList',
        component: () => import('@/views/recharge/recharge-order/list.vue'),
        meta: { name: '充值流水' }
      },
      {
        path: '/recharge/rechargeOrder/detail/:id?',
        name: 'RechargeOrderDetail',
        component: () => import('@/views/recharge/recharge-order/detail.vue'),
        meta: { name: '充值流水详情', hidden: true, activeMenu: '/recharge/rechargeOrder/list' }
      },
      {
        path: '/recharge/charge_value/list',
        name: 'ChargeValueList',
        component: () => import('@/views/recharge/charge-value/list.vue'),
        meta: { name: '充值挡位管理' }
      },
      {
        path: '/recharge/charge_value/add',
        name: 'ChargeValueAdd',
        component: () => import('@/views/recharge/charge-value/addOrEdit.vue'),
        meta: { name: '新增充值挡位', hidden: true, activeMenu: '/recharge/charge_value/list' }
      },
      {
        path: '/recharge/charge_value/edit/:id?',
        name: 'ChargeValueEdit',
        component: () => import('@/views/recharge/charge-value/addOrEdit.vue'),
        meta: { name: '编辑充值挡位', hidden: true, activeMenu: '/recharge/charge_value/list' }
      },
      {
        path: '/recharge/withdraw/list',
        name: 'WithdrawList',
        component: () => import('@/views/recharge/withdraw-list/list.vue'),
        meta: { name: '退款申请' }
      }
    ]
  },
  {
    path: '/car',
    name: 'Car',
    component: () => import('@/views/car/index.vue'),
    meta: { name: '车辆管理', icon: 'el-icon-truck' },
    children: [
      {
        path: '/car/brand/list',
        name: 'CarBrandList',
        component: () => import('@/views/car/car-brand/list.vue'),
        meta: { name: '品牌管理', keepAlive: true }
      },
      {
        path: '/car/brand/add',
        name: 'CarBrandAdd',
        component: () => import('@/views/car/car-brand/addOrEdit.vue'),
        meta: { name: '新增品牌', hidden: true, activeMenu: '/car/brand/list' }
      },
      {
        path: '/car/brand/edit/:id?',
        name: 'CarBrandEdit',
        component: () => import('@/views/car/car-brand/addOrEdit.vue'),
        meta: { name: '编辑品牌', hidden: true, activeMenu: '/car/brand/list' }
      },
      {
        path: '/car/model/list',
        name: 'CarModelList',
        component: () => import('@/views/car/car-model/list.vue'),
        meta: { name: '车型管理', keepAlive: true }
      },
      {
        path: '/car/model/add',
        name: 'CarModelAdd',
        component: () => import('@/views/car/car-model/addOrEdit.vue'),
        meta: { name: '新增车型', hidden: true, activeMenu: '/car/model/list' }
      },
      {
        path: '/car/model/edit/:id?',
        name: 'CarModelEdit',
        component: () => import('@/views/car/car-model/addOrEdit.vue'),
        meta: { name: '编辑车型', hidden: true, activeMenu: '/car/model/list' }
      }
    ]
  },
  {
    path: '/park',
    name: 'Park',
    component: () => import('@/views/park/index.vue'),
    meta: { name: '停车场管理', icon: 'el-icon-office-building' },
    children: [
      {
        path: '/park/station/list',
        name: 'ParkStationList',
        component: () => import('@/views/park/park-station/list.vue'),
        meta: { name: '充电站管理', keepAlive: true }
      },
      {
        path: '/park/station/add',
        name: 'ParkStationAdd',
        component: () => import('@/views/park/park-station/addOrEdit.vue'),
        meta: { name: '新增充电站', hidden: true, activeMenu: '/park/station/list' }
      },
      {
        path: '/park/station/edit/:id?',
        name: 'ParkStationEdit',
        component: () => import('@/views/park/park-station/addOrEdit.vue'),
        meta: { name: '编辑充电站', hidden: true, activeMenu: '/park/station/list' }
      },
      {
        path: '/park/charge_lock/list',
        name: 'ChargeLockList',
        component: () => import('@/views/park/charge-lock/list.vue'),
        meta: { name: '地锁管理', keepAlive: true }
      },
      {
        path: '/park/charge_lock/add',
        name: 'ChargeLockAdd',
        component: () => import('@/views/park/charge-lock/addOrEdit.vue'),
        meta: { name: '新增地锁', hidden: true, activeMenu: '/park/charge_lock/list' }
      },
      {
        path: '/park/charge_lock/edit/:id?',
        name: 'ChargeLockEdit',
        component: () => import('@/views/park/charge-lock/addOrEdit.vue'),
        meta: { name: '编辑地锁', hidden: true, activeMenu: '/park/charge_lock/list' }
      },
      {
        path: '/park/space/list',
        name: 'ParkSpaceList',
        component: () => import('@/views/park/park-space/list.vue'),
        meta: { name: '停车位管理', keepAlive: true }
      },
      {
        path: '/park/space/add',
        name: 'ParkSpaceAdd',
        component: () => import('@/views/park/park-space/addOrEdit.vue'),
        meta: { name: '新增停车位', hidden: true, activeMenu: '/park/space/list' }
      },
      {
        path: '/park/space/edit/:id?',
        name: 'ParkSpaceEdit',
        component: () => import('@/views/park/park-space/addOrEdit.vue'),
        meta: { name: '编辑停车位', hidden: true, activeMenu: '/park/space/list' }
      },
      {
        path: '/park/charge_pile/list',
        name: 'ChargePileList',
        component: () => import('@/views/park/charge-pile/list.vue'),
        meta: { name: '充电桩管理', keepAlive: true }
      },
      {
        path: '/park/charge_pile/add',
        name: 'ChargePileAdd',
        component: () => import('@/views/park/charge-pile/addOrEdit.vue'),
        meta: { name: '新增充电桩', hidden: true, activeMenu: '/park/charge_pile/list' }
      },
      {
        path: '/park/charge_pile/edit/:id?',
        name: 'ChargePileEdit',
        component: () => import('@/views/park/charge-pile/addOrEdit.vue'),
        meta: { name: '编辑充电桩', hidden: true, activeMenu: '/park/charge_pile/list' }
      },
      {
        path: '/park/charge_pile/detail/:id?',
        name: 'ChargePileDetail',
        component: () => import('@/views/park/charge-pile/detail.vue'),
        meta: { name: '充电桩详情', hidden: true, activeMenu: '/park/charge_pile/list' }
      }
      // {
      //   path: '/park/charge_gun/list',
      //   name: 'ChargeGunList',
      //   component: () => import('@/views/park/charge-gun/list.vue'),
      //   meta: { name: '充电枪管理' }
      // },
      // {
      //   path: '/park/charge_gun/add',
      //   name: 'ChargeGunAdd',
      //   component: () => import('@/views/park/charge-gun/addOrEdit.vue'),
      //   meta: { name: '新增', hidden: true }
      // },
      // {
      //   path: '/park/charge_gun/edit/:id?',
      //   name: 'ChargeGunEdit',
      //   component: () => import('@/views/park/charge-gun/addOrEdit.vue'),
      //   meta: { name: '编辑', hidden: true }
      // },

      // {
      //   path: '/park/data_static',
      //   name: 'DataStatic',
      //   component: () => import('@/views/park/data-static/index.vue'),
      //   meta: { name: '数据分析' }
      // }
    ]
  },
  {
    path: '/operation',
    name: 'Operation',
    component: () => import('@/views/operation/'),
    meta: { name: '运营管理', icon: 'el-icon-s-help' },
    children: [
      {
        path: '/operation/coupon/list',
        name: 'CouponList',
        component: () => import('@/views/operation/coupon/list.vue'),
        meta: { name: '优惠券管理', keepAlive: true }
      },
      {
        path: '/operation/coupon/add',
        name: 'CouponAdd',
        component: () => import('@/views/operation/coupon/addOrEdit.vue'),
        meta: { name: '新增优惠券', hidden: true, activeMenu: '/operation/coupon/list' }
      },
      {
        path: '/operation/coupon/edit/:id?',
        name: 'CouponEdit',
        component: () => import('@/views/operation/coupon/addOrEdit.vue'),
        meta: { name: '编辑优惠券', hidden: true, activeMenu: '/operation/coupon/list' }
      },

      {
        path: '/operation/help_center/list',
        name: 'HelpCenterList',
        component: () => import('@/views/operation/help-center/list.vue'),
        meta: { name: '帮助中心' }
      },
      {
        path: '/operation/help_center/add',
        name: 'HelpCenterAdd',
        component: () => import('@/views/operation/help-center/addOrEdit.vue'),
        meta: { name: '新增帮助中心', hidden: true, activeMenu: '/operation/help_center/list' }
      },
      {
        path: '/operation/help_center/edit/:id?',
        name: 'HelpCenterEdit',
        component: () => import('@/views/operation/help-center/addOrEdit.vue'),
        meta: { name: '编辑帮助中心', hidden: true, activeMenu: '/operation/help_center/list' }
      },
      {
        path: '/operation/agree_seetings/list',
        name: 'AgreeSeetingsList',
        component: () => import('@/views/operation/contract/list.vue'),
        meta: { name: '协议设置' }
      },
      {
        path: '/operation/banner/list',
        name: 'BannerList',
        component: () => import('@/views/operation/index-banner/list.vue'),
        meta: { name: 'banner设置' }
      }
    ]
  },
  {
    path: '/powerManage',
    name: 'PowerManage',
    redirect: '/powerManage/user/list',
    component: () => import('@/views/powerManage/index.vue'),
    meta: { name: '权限管理', icon: 'el-icon-menu' },
    children: [
      {
        path: '/powerManage/user/list',
        name: 'UserList',
        component: () => import('@/views/powerManage/user/list.vue'),
        meta: { name: '管理员' }
      },
      {
        path: '/powerManage/user/add',
        name: 'UserAdd',
        component: () => import('@/views/powerManage/user/addEdit.vue'),
        meta: { name: '新增管理员', hidden: true, activeMenu: '/powerManage/user/list' }
      },
      {
        path: '/powerManage/user/edit/:id?',
        name: 'UserEdit',
        component: () => import('@/views/powerManage/user/addEdit.vue'),
        meta: { name: '编辑管理员', hidden: true, activeMenu: '/powerManage/user/list' }
      },
      {
        path: '/powerManage/role/list',
        name: 'RoleList',
        component: () => import('@/views/powerManage/role/list.vue'),
        meta: { name: '角色管理' }
      },
      {
        path: '/powerManage/role/addEdit',
        name: 'RoleAdd',
        component: () => import('@/views/powerManage/role/addEdit.vue'),
        meta: { name: '新增角色', hidden: true, activeMenu: '/powerManage/role/list' }
      },
      {
        path: '/powerManage/role/addEdit/:id?',
        name: 'RoleEdit',
        component: () => import('@/views/powerManage/role/addEdit.vue'),
        meta: { name: '编辑角色', hidden: true, activeMenu: '/powerManage/role/list' }
      },
      {
        path: '/powerManage/menu/list',
        name: 'MenuList',
        component: () => import('@/views/powerManage/menu/list.vue'),
        meta: { name: '菜单管理', keepAlive: true }
      },
      {
        path: '/powerManage/menu/addEdit/:menuId?/:parentId?',
        name: 'MenuAdd',
        component: () => import('@/views/powerManage/menu/addEdit.vue'),
        meta: { name: '新增菜单', hidden: true, activeMenu: '/powerManage/menu/list' }
      },
      {
        path: '/powerManage/menu/addEdit/:menuId?/:parentId?',
        name: 'MenuEdit',
        component: () => import('@/views/powerManage/menu/addEdit.vue'),
        meta: { name: '编辑菜单', hidden: true, activeMenu: '/powerManage/menu/list' }
      },
      // {
      //   path: '/powerManage/dept/list',
      //   name: 'Dept',
      //   component: () => import('@/views/powerManage/dept/list.vue'),
      //   meta: { name: '部门管理' }
      // },
      {
        path: '/powerManage/area/list',
        name: 'AreaList',
        component: () => import('@/views/powerManage/area/list.vue'),
        meta: { name: '省市区管理', keepAlive: true }
      },
      {
        path: '/powerManage/area/addEdit/:parent_id?',
        name: 'AreaAdd',
        component: () => import('@/views/powerManage/area/addEdit.vue'),
        meta: { name: '新增省市区', hidden: true, activeMenu: '/powerManage/area/list' }
      },
      {
        path: '/powerManage/area/addEdit/:menuId?',
        name: 'AreaEdit',
        component: () => import('@/views/powerManage/area/addEdit.vue'),
        meta: { name: '编辑省市区', hidden: true, activeMenu: '/powerManage/area/list' }
      }
    ]
  },

  {
    path: '/systemManage',
    name: 'SystemManage',
    component: () => import('@/views/systemManage/index.vue'),
    meta: { name: '系统设置', icon: 'el-icon-setting' },
    children: [
      {
        path: '/systemManage/dictManage/list',
        name: 'DictManageList',
        component: () => import('@/views/systemManage/dictManage/index.vue'),
        meta: { name: '字典管理' }
      },
      {
        path: '/systemManage/dictManage/add',
        name: 'dictManageAdd',
        component: () => import('@/views/systemManage/dictManage/detail-dict.vue'),
        meta: { hidden: true, name: '添加字典', activeMenu: '/systemManage/dictManage/list' }
      },
      {
        path: '/systemManage/dictManage/edit/:id?',
        name: 'dictManageEdit',
        component: () => import('@/views/systemManage/dictManage/detail-dict.vue'),
        meta: { hidden: true, name: '编辑字典', activeMenu: '/systemManage/dictManage/list' }
      },
      {
        path: '/systemManage/dictValueManage',
        name: 'DictValueManage',
        component: () => import('@/views/systemManage/dictManage/list-dict-value.vue'),
        meta: { hidden: true, name: '字典值管理', activeMenu: '/systemManage/dictManage/list' }
      }
      // {
      //   path: '/systemManage/config/list',
      //   name: 'ConfigList',
      //   component: () => import('@/views/systemManage/config/list.vue'),
      //   meta: { name: '开发设置' }
      // }
      // {
      //   path: '/systemManage/app_settings/list',
      //   name: 'SysAppSettingsList',
      //   component: () => import('@/views/systemManage/app-settings/list.vue'),
      //   meta: { name: 'app设置' }
      // },
      // {
      //   path: '/systemManage/app_settings/edit/:id?',
      //   name: 'SysAppSettingsEdit',
      //   component: () => import('@/views/systemManage/app-settings/addOrEdit.vue'),
      //   meta: { name: '编辑', hidden: true }
      // }
      // {
      //     path: '/systemManage/logManage/list',
      //     name: 'LogManageList',
      //     component: () => import('@/views/systemManage/logManage/list.vue'),
      //     meta: {name: '操作日志'}
      // }
    ]
  }
]
