<template>
  <div class="top-menu">
    <div class="left">
      <a-icon class="icon" :type="collapsed ? 'menu-unfold' : 'menu-fold'" @click="changeCollapsed" />
      <a-icon class="icon" type="reload" @click="refresh" />
      <!-- <el-breadcrumb separator="/">
              <el-breadcrumb-item v-for=" item in bread" :key="item.path" :to="{ path:item.path }">{{item.name}}</el-breadcrumb-item>

            </el-breadcrumb> -->
    </div>

    <div class="right">
      <el-dropdown trigger="click" @command="handleCommand">
        <span style="cursor: pointer">欢迎您：{{ userInfo.username }}</span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="updatePwd">修改密码</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <div class="logout" @click="logout">
        <a-icon class="icon" type="logout" />
        退出登录
      </div>
    </div>

    <el-dialog title="修改密码" :visible.sync="dialogVisible" width="" :before-close="handleClose">
      <el-form ref="form" :model="formData" label-width="80px" :rules="rules">
        <el-form-item label="修改密码" prop="password">
          <el-input style="width: 300px" v-model="formData.password"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">修改</el-button>
          <el-button @click="dialogVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { getWWWInfoAPI, updatePasswordAPI } from './api'
// import {addTeacher, editTeacher} from "@/views/feedback/feedback-manage/api";

export default {
  name: 'TopMenu',
  model: {
    prop: 'collapsed'
  },
  props: {
    collapsed: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      userName: '',
      dialogVisible: false,

      formData: {
        password: ''
      },

      rules: {
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' },
          { min: 6, message: '密码长度最少为6位', trigger: 'blur' }
        ]
      }
    }
  },

  created() {
    this.userName = localStorage.getItem('userName')
  },

  computed: {
    ...mapGetters({
      userInfo: 'getUserInfo'
    }),
    ...mapState({ userInfo: (state) => state.user.userInfo }),
    bread() {
      let breadList = []
      breadList = this.$route.matched.map((item) => {
        return {
          path: item.path,
          name: item.meta.name
        }
      })
      return breadList
    }
  },

  methods: {
    changeCollapsed() {
      this.$emit('input', !this.collapsed)
      this.$bus.$emit('showLogo')
    },

    handleCommand(command) {
      if (command === 'updatePwd') {
        this.dialogVisible = true
      }
    },

    onSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          updatePasswordAPI(this.formData).then((res) => {
            this.$message.success('更新密码成功')
            this.formData.password = ''
            this.dialogVisible = false
          })
        } else {
          return false
        }
      })
    },

    handleClose() {
      this.dialogVisible = false
    },

    getWWWInfo() {
      getWWWInfoAPI().then((res) => {
        console.log(res, '@@@')
        this.wwwInfo = res
      })
    },

    refresh() {
      history.go(0)
    },

    logout() {
      this.$store.dispatch('Logout').then(() => {
        this.$router.push('/login')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.top-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px 24px;

  .icon {
    margin-right: 20px;
    font-size: 16px;
  }

  .right {
    display: flex;
    align-items: center;

    .icon {
      margin-left: 20px;
      margin-right: 0;
    }

    .logout {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }

  .left {
    display: flex;
    align-items: center;
  }
}
</style>
