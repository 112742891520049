<template>
  <a-upload
    name="file"
    :multiple="isMulti"
    :customRequest="uploadFile"
    :fileList="fileList"
    listType="picture-card"
    :remove="removeFile"
  >
    <a-button type="primary"> <a-icon type="upload" />选择图片</a-button>
  </a-upload>
</template>

<script>
export default {
  name: "Uploader",

  props: {
    value: {
      type: [String, Array],
      default: "",
    },

    isMulti: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      fileList: [],
    };
  },

  computed: {
    defaultFileList() {
      if (Array.isArray(this.value)) {
        let index = 0;
        return this.value.map((item) => {
          index++;
          return {
            uid: index,
            status: "done",
            url: item,
          };
        });
      } else {
        if (this.value) {
          return [{
            uid: "1",
            status: "done",
            name: '123123.png',
            url: this.value,
          }]
        } else {
          return [];
        }
      }
    },
  },

  watch: {
    value () {
      if (Array.isArray(this.value)) {
        if (this.value.length ===0) return this.fileList = []
        let index = 0;
        this.fileList = this.value.map((item) => {
          index++;
          return {
            uid: index,
            status: "done",
            url: item,
          };
        });
      } else {
        if (this.value) {
          this.fileList = [{
            uid: "1",
            status: "done",
            name: '123123.png',
            url: this.value,
          }]
        } else {
          this.fileList = [];
        }
      }
    }
  },

  methods: {
    uploadFile(file) {
      let formData = new FormData();
      formData.append("file", file.file);
      return this.$http({
        url: "/common/file/upload",
        method: "POST",
        data: formData,
      }).then((res) => {
        let uuid = this.fileList[this.fileList.length - 1]?.uid;
        uuid = (uuid || 0) + 1
        this.fileList.push({
          uid: uuid,
          status: "done",
          url: res.url,
          name: res.path,
        })
        let url = Array.isArray(this.value) ? [...this.value, res.url] : res.url
        this.$emit('input', url)
      });
    },

    removeFile (file) {
      let reset = this.fileList.filter(item => item.uid !== file.uid).map(item => item.url)
      this.$emit('input', reset.length > 0 ? reset : '')
    }
  },
};
</script>

<style>
</style>
